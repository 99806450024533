<template>
  <div class="newList">
    <div class="content">
      <el-row :gutter="20" >
        <el-col :span="18">
          <router-view />
        </el-col>
        <el-col :span="6">
          <rightBox/>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import rightBox from './components/right.vue';
  export default {
  name: "newsPage",
  components:{rightBox},
  data() {
    return {
      
      
      }
  },
  methods: {
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    }
    }
  }
</script>

